// store/slices/formBuilderSlice.ts
import { FormConfigType, initialCustomFormState } from "@/utils/types/store";

export interface FormBuilderState {
  currentFormData: FormConfigType;
  tempFormData: FormConfigType | null;
  isDirty: boolean;
  formId?: string;
}

export interface FormBuilderActions {
  setCurrentFormData: (data: FormConfigType) => void;
  setFormId: (id: string) => void;
  updateTempFormData: (data: Partial<FormConfigType>) => void;
  saveChanges: () => void;
  discardChanges: () => void;
  resetForm: () => void;
}

export const formBuilderSlice = (
  set: any,
): { data: FormBuilderState; actions: FormBuilderActions } => ({
  data: {
    currentFormData: initialCustomFormState,
    tempFormData: null,
    isDirty: false,
    formId: undefined,
  },
  actions: {
    setFormId: (id: string) => {
      set((state: any) => {
        state.data.formId = id;
      });
    },
    setCurrentFormData: (data: FormConfigType) => {
      set((state: any) => {
        state.data.currentFormData = data;
        state.data.tempFormData = null;
        state.data.isDirty = false;
      });
    },
    updateTempFormData: (updates: Partial<FormConfigType>) => {
      set((state: any) => {
        const currentTemp =
          state.data.tempFormData || state.data.currentFormData;
        state.data.tempFormData = {
          ...currentTemp,
          ...updates,
        };
        state.data.isDirty = true;
      });
    },
    saveChanges: () => {
      set((state: any) => {
        if (state.data.tempFormData) {
          state.data.currentFormData = state.data.tempFormData;
          state.data.tempFormData = null;
          state.data.isDirty = false;
        }
      });
    },
    discardChanges: () => {
      set((state: any) => {
        state.data.tempFormData = null;
        state.data.isDirty = false;
      });
    },
    resetForm: () => {
      set((state: any) => {
        state.data.currentFormData = initialCustomFormState;
        state.data.tempFormData = null;
        state.data.isDirty = false;
      });
    },
  },
});
