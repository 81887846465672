export interface FormConfigType {
  ownerEmail?: string;
  layout: {
    width: string;
    padding?: string;
    borderRadius: string;
    backgroundColor: string;
    paddingTop?: string;
    paddingRight?: string;
    paddingBottom?: string;
    paddingLeft?: string;
  };
  header: {
    title: string;
    className?: string;
    visible: boolean;
    alignment?: "left" | "center" | "right";
  };
  description: {
    text: string;
    className?: string;
    visible: boolean;
    alignment?: "left" | "center" | "right";
  };
  formFields: {
    type: string;
    name: string;
    label: string | null;
    placeholder?: string;
    required: boolean;
    errorText: string;
    width: string;
    className?: string;
    defaultValue?: string;
    isDefaultField?: boolean;
    visible: boolean;
    formFieldDisplayName?: string | null;
  }[];
  button: {
    text: string;
    className?: string;
    alignment?: "left" | "center" | "right";
  };
  logo: {
    visible: boolean;
    url: string | null;
    width?: string;
    height?: string;
    className?: string;
  };
  formStyles: {
    [key: string]: React.CSSProperties | any;
  };
  automatedEmail: {
    recipient: string;
    subject: string;
    body: string;
  };
  formData: Record<string, any>;
  submissionStatus: string;
  formError: string;
  customCSS: {
    enabled: boolean;
    css: string | null;
  };
  showBrandingMessage?: boolean;
}

export const initialCustomFormState: FormConfigType = {
  ownerEmail: "",
  layout: {
    width: "1095px",
    // padding: "53px",
    borderRadius: "20px",
    backgroundColor: "#fff",
    paddingTop: "53px",
    paddingRight: "53px",
    paddingBottom: "53px",
    paddingLeft: "53px",
  },
  header: {
    title: "Get Your Free Email Marketing Audit",
    className: "header-class",
    visible: true,
  },
  description: {
    text: `<p>Enter your email address and a <a href="https://admin.mailchimp.com/account/api/" rel="noopener noreferrer" target="_blank">Mailchimp API key</a> to generate a report.</p>`,
    className: "description-class",
    visible: true,
  },
  formFields: [
    {
      type: "email",
      name: "email",
      label: "",
      placeholder: "Email Address",
      required: true,
      errorText: "Email is required",
      width: "50%",
      className: "email-field",
      defaultValue: "",
      isDefaultField: true,
      visible: true,
      formFieldDisplayName: "Email address",
    },
    {
      type: "text",
      name: "mailchimpKey",
      label: "",
      placeholder: "Mailchimp API Key",
      required: true,
      errorText: "Mailchimp API Key is required",
      width: "50%",
      className: "",
      defaultValue: "",
      isDefaultField: true,
      visible: true,
      formFieldDisplayName: "Mailchimp API key",
    },
    {
      type: "text",
      name: "firstName",
      label: "",
      placeholder: "First Name",
      required: false,
      errorText: "First Name is required",
      width: "50%",
      className: "",
      defaultValue: "",
      isDefaultField: false,
      visible: false,
      formFieldDisplayName: "First name",
    },
    {
      type: "text",
      name: "lastName",
      label: "",
      placeholder: "Last Name",
      errorText: "",
      required: false,
      width: "50%",
      className: "",
      visible: false,
      formFieldDisplayName: "Last name",
    },
    {
      type: "number",
      name: "phone",
      label: "",
      placeholder: "",
      required: false,
      errorText: "",
      width: "50%",
      className: "",
      visible: false,
      formFieldDisplayName: "Phone number",
    },
  ],
  button: {
    text: "Download Report",
    className: "",
  },
  logo: {
    visible: false,
    url: "https://images.prismic.io/kadima-digital/65977bd3531ac2845a2721a8_Group.png?auto=format%2Ccompress&fit=max&w=3840",
    width: "100px",
    height: "50px",
    className: "logo-class",
  },
  formStyles: {
    Title: {
      fontSize: "35px",
      color: "#336DFF",
      className: "title-style",
    },
    Description: {
      fontSize: "14px",
      color: "#444A6D",
      className: "description-style",
    },
    FieldLabel: {
      fontSize: "16px",
      color: "#333",
      requiredColor: "#FA5A7D",
      className: "label-style",
    },
    FieldText: {
      fontSize: "16px",
      color: "#333",
      backgroundColor: "#f5f5f5",
      className: "input-style",
    },
    Button: {
      fontSize: "16px",
      color: "#fff",
      backgroundColor: "#336DFF",
      className: "button-style",
    },
  },
  automatedEmail: {
    recipient: "example@email.com",
    subject: "Form Submission",
    body: "A new form submission has been received.",
  },
  formData: {},
  submissionStatus: "",
  formError: "",
  customCSS: {
    enabled: false,
    css: null,
  },
  showBrandingMessage: true,
};
