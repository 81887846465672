// store/slices/userSlice.ts
import { FormConfigType, initialCustomFormState } from "@/utils/types/store";

export interface UserState {
  userId: string | null;
  userDetails: {
    userName?: string | null;
    email?: string | null;
    role?: string;
  };
  userCustomFormData: FormConfigType;
  profilePicture: string | null;
  brandLogo: string | null;
  brandIcon?: string | null;
}

export interface UserActions {
  setUserData: (data: { userId: string }) => void;
  setUserDetails: ({
    userName,
    email,
  }: {
    userName?: string | null;
    email?: string | null;
  }) => void;
  setUserCustomFormData: (data: FormConfigType) => void;
  setProfilePicture: (profilePicture: string) => void;
  setBrandLogo: (brandLogo: string) => void;
  setBrandIcon: (brandIcon: string) => void;
}

export const userSlice = (
  set: any,
): { data: UserState; actions: UserActions } => ({
  data: {
    userId: null,
    userDetails: {
      userName: null,
      email: null,
      role: "user",
    },
    userCustomFormData: initialCustomFormState,
    profilePicture: null,
    brandLogo: null,
    brandIcon: null,
  },
  actions: {
    setUserData: (data: { userId: string }) => {
      set((state: any) => {
        state.data.userId = data.userId;
      });
    },
    setUserDetails: ({ userName, email }) => {
      set((state: any) => {
        state.data.userDetails = {
          userName,
          email,
        };
      });
    },
    setUserCustomFormData: (formData: any) => {
      set((state: any) => {
        state.data.userCustomFormData = formData;
      });
    },
    setProfilePicture: (profilePicture: string) => {
      set((state: any) => {
        state.data.profilePicture = profilePicture;
      });
    },
    setBrandLogo: (brandLogo: string) => {
      set((state: any) => {
        state.data.brandLogo = brandLogo;
      });
    },
    setBrandIcon: (brandIcon: string) => {
      set((state: any) => {
        state.data.brandIcon = brandIcon;
      });
    },
  },
});
