// store/index.ts

import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";
import { toastSlice } from "./slices/toastSlice";
import { modalSlice } from "./slices/modalSlice";
import { colorSlice } from "./slices/colorSlice";
import { userSlice } from "./slices/userSlice";
import { mailchimpSlice } from "./slices/mailchimpSlice";
import { dashboardSlice } from "./slices/dashboardSlice";
import { customReportSlice } from "./slices/customReportSlice";
import { uiSlice } from "./slices/uiSlice";
import { processedDataSlice } from "./slices/processedDataSlice";
import { ZustandStore } from "./types/store.types";
import { loadingSlice } from "./slices/loadingSlice";
import { formBuilderSlice } from "./slices/formBuilderSlice";

// initial state for each slice
const initialToastState = toastSlice(() => {}).data;
const initialModalState = modalSlice(() => {}).data;
const initialColorState = colorSlice(() => {}).data;
const initialUserState = userSlice(() => {}).data;
const initialMailchimpState = mailchimpSlice(() => {}).data;
const initialDashboardState = dashboardSlice(() => {}).data;
const initialCustomReportState = customReportSlice(() => {}).data;
const initialUiState = uiSlice(() => {}).data;
const initialProcessedDataState = processedDataSlice(() => {}).data;
const initialLoadingState = loadingSlice(() => {}).data;
const initialFormBuilderState = formBuilderSlice(() => {}).data;

const isBrowser = typeof window !== "undefined";

const customStorage = {
  getItem: (name: string) => {
    if (!isBrowser) return null;

    try {
      return localStorage.getItem(name);
    } catch (err) {
      console.warn(`Error reading '${name}' from localStorage:`, err);
      return null;
    }
  },
  setItem: (name: string, value: string) => {
    if (!isBrowser) return null;

    try {
      localStorage.setItem(name, value);
    } catch (err) {
      // Calculate size of the value trying to be stored
      const valueSize = new Blob([value]).size / 1024 / 1024; // Size in MB
      console.warn(
        `Storage quota exceeded for '${name}'. ` +
          `Attempted to store ${valueSize.toFixed(2)}MB of data. ` +
          `Browser storage limits are typically 5-10MB.`,
        "\nValue causing the error:",
        JSON.parse(value),
      );
    }
  },
  removeItem: (name: string) => {
    if (!isBrowser) return null;

    try {
      localStorage.removeItem(name);
    } catch (err) {
      console.warn(`Error removing '${name}' from localStorage:`, err);
    }
  },
};
// Determine which parts of the state to persist
const shouldPersist = (key: string): boolean => {
  const persistedKeys = [
    "modal",
    "primaryColor",
    "userId",
    "userDetails",
    "userCustomFormData",
    "mailchimpProfile",
    "dashboardData",
    "selectedDashboard",
    "customReports",
    "selectedCustomReport",
    "sideBarVisible",
    // "processedData",
    "processingStatus",
    "loading",
    "currentFormData",
    "profilePicture",
  ];
  return persistedKeys.some((persistedKey) => key.startsWith(persistedKey));
};

export const zustandStore = create<ZustandStore>()(
  persist(
    immer((set) => ({
      data: {
        _hydrated: false,
        ...toastSlice(set).data,
        ...modalSlice(set).data,
        ...colorSlice(set).data,
        ...userSlice(set).data,
        ...mailchimpSlice(set).data,
        ...dashboardSlice(set).data,
        ...customReportSlice(set).data,
        ...uiSlice(set).data,
        ...processedDataSlice(set).data,
        ...loadingSlice(set).data,
        ...formBuilderSlice(set).data,
      },
      actions: {
        ...toastSlice(set).actions,
        ...modalSlice(set).actions,
        ...colorSlice(set).actions,
        ...userSlice(set).actions,
        ...mailchimpSlice(set).actions,
        ...dashboardSlice(set).actions,
        ...customReportSlice(set).actions,
        ...uiSlice(set).actions,
        ...processedDataSlice(set).actions,
        ...loadingSlice(set).actions,
        ...formBuilderSlice(set).actions,
        resetStore: () => {
          set((state) => {
            state.data = {
              ...initialToastState,
              ...initialModalState,
              ...initialColorState,
              ...initialUserState,
              ...initialMailchimpState,
              ...initialDashboardState,
              ...initialCustomReportState,
              ...initialUiState,
              ...initialProcessedDataState,
              ...initialLoadingState,
              ...initialFormBuilderState,
            };
          });
        },
      },
    })),
    {
      name: "zustand-store",
      storage: createJSONStorage(() => customStorage),
      // partialize: (state) => ({ data: state.data }),
      partialize: (state) => {
        const persistedData: Record<string, any> = {};
        Object.entries(state.data).forEach(([key, value]) => {
          if (key === "mailchimpProfile") {
            // Persist only specific properties
            persistedData[key] = {
              mailchimpKeys: value.mailchimpKeys,
              userEmail: value.userEmail,
              selectedMailchimpKeyId: value.selectedMailchimpKeyId,
            };
          } else if (shouldPersist(key)) {
            persistedData[key] = value;
          }
        });
        return { data: persistedData };
      },
      onRehydrateStorage: (state) => {
        if (state) {
          // state.data._hydrated = true;
        }
      },
    },
  ),
);
