// store/slices/processedDataSlice.ts
export interface ProcessingStatus {
  status: "idle" | "processing" | "completed" | "failed";
  progress: number;
  currentStep: string;
  error?: string;
  trackerId?: string | null;
  mailchimpKeyId?: string;
}
export interface ProcessedDataState {
  processedData: {
    campaignsData?: any;
    automationData?: any;
    allEmails?: any;
    ecommerceData?: any;
    revenueData?: any;
    subscribersData?: any;
    deliverabilityMetrics?: any;
  };
  processingStatus: ProcessingStatus;
}

export interface ProcessedDataActions {
  setProcessedData: (data: any) => void;
  addProcessedData: (data: any) => void;
  updateProcessingStatus: (
    status: Partial<ProcessedDataState["processingStatus"]>,
  ) => void;
  resetProcessingStatus: () => void;
}

const initialProcessingStatus: ProcessingStatus = {
  status: "idle",
  progress: 0,
  currentStep: "",
  error: undefined,
  trackerId: null,
};

export const processedDataSlice = (
  set: any,
): { data: ProcessedDataState; actions: ProcessedDataActions } => ({
  data: {
    processedData: {},
    processingStatus: initialProcessingStatus,
  },
  actions: {
    setProcessedData: (data: any) => {
      set((state: any) => {
        state.data.processedData = data;
      });
    },
    addProcessedData: (data: any) => {
      set((state: any) => {
        state.data.processedData = { ...state.data.processedData, ...data };
      });
    },
    updateProcessingStatus: (
      status: Partial<ProcessedDataState["processingStatus"]>,
    ) => {
      set((state: any) => {
        state.data.processingStatus = {
          ...state.data.processingStatus,
          ...status,
        };
      });
    },
    resetProcessingStatus: () => {
      set((state: any) => {
        state.data.processingStatus = {
          status: "idle",
          progress: 0,
          currentStep: "",
        };
      });
    },
  },
});
